import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject } from 'rxjs';
import { AlertService } from './alert.service';
import { Log, LogType } from './log';
import { UserService } from './user.service';
import { User } from '../user/user';

@Injectable()
export class LoggerService {
  private baseUrl = environment.cubCloudUrl + 'log';

  private logs = new ReplaySubject<string>(1);
  logs$ = this.logs.asObservable();
  deviceInfo = null;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private alertService: AlertService,
    private userService: UserService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    //console.log('device info', this.deviceInfo);
  }

  log(msg: string) {
    this.logs.next(msg);
  }

  logPageVisit(page: string, id: string, otherId?: string) {
    let log = new Log();
    log.source = page;
    log.message = 'page visit';
    log.stackTrace = id;
    log.type = LogType.PortalTracking;
    log.os = this.deviceInfo.os;
    log.osVersion = this.deviceInfo.os_version;
    log.browser = this.deviceInfo.browser;
    log.browserVersion = this.deviceInfo.browser_version;
    log.device = this.deviceInfo.deviceType;
    log.userAgent = this.deviceInfo.userAgent;
    log.user = (JSON.parse(localStorage.getItem('user'))).accountName;
    // hijacking the session id field since it isn't being used to send an additional id (propid for leases)
    if (otherId) {
      log.sessionId = otherId;
    }
    this.logToServer(log).subscribe(l => {
      this.userService.getRecents(); // this reloads the recents menu
    });
  }

  logError(errorMessage: string, page: string, id: string, showAlert: boolean) {
    let log = new Log();
    log.source = page;
    log.message = errorMessage;
    log.stackTrace = id;
    log.type = LogType.Error;
    log.os = this.deviceInfo.os;
    log.osVersion = this.deviceInfo.os_version;
    log.browser = this.deviceInfo.browser;
    log.browserVersion = this.deviceInfo.browser_ersion;
    log.device = this.deviceInfo.device;
    log.userAgent = this.deviceInfo.userAgent;
    log.user = (JSON.parse(localStorage.getItem('user'))).accountName;
    this.logToServer(log).subscribe();
    if (showAlert) {
      this.alertService.error(errorMessage);
    }
  }

  logSuccess(activity: string, page: string, id: string, showAlert: boolean) {
    let log = new Log();
    log.source = page;
    log.message = activity;
    log.stackTrace = id;
    log.type = LogType.Activity;
    log.os = this.deviceInfo.os;
    log.osVersion = this.deviceInfo.os_version;
    log.browser = this.deviceInfo.browser;
    log.browserVersion = this.deviceInfo.browser_ersion;
    log.device = this.deviceInfo.device;
    log.userAgent = this.deviceInfo.userAgent;
    log.user = (JSON.parse(localStorage.getItem('user'))).accountName;
    this.logToServer(log).subscribe();
    if (showAlert) {
      this.alertService.success(activity);
    }
  }

  logNote(note: string, page: string, id: string, showAlert: boolean) {
    let log = new Log();
    log.source = page;
    log.message = note;
    log.stackTrace = id;
    log.type = LogType.Activity;
    log.os = this.deviceInfo.os;
    log.osVersion = this.deviceInfo.os_version;
    log.browser = this.deviceInfo.browser;
    log.browserVersion = this.deviceInfo.browser_ersion;
    log.device = this.deviceInfo.device;
    log.userAgent = this.deviceInfo.userAgent;
    log.user = (JSON.parse(localStorage.getItem('user'))).accountName;
    this.logToServer(log).subscribe();
    if (showAlert) {
      this.alertService.info(note);
    }
  }

  logToServer(log: Log) {
    const url = `${this.baseUrl}`;
    //console.log('device info', JSON.stringify(log));
    return this.http.post<boolean>(url, JSON.stringify(log), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }
}
