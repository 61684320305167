import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/user.service';
import { UserMenu } from '../user-menu';
import { LoggerService } from '../../core/logger.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GldataTransferDataComponent } from '../../misc/gldata-transfer/gldata-transfer-data/gldata-transfer-data.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgFor, NgIf } from '@angular/common';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


@Component({
    selector: 'cub-link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss'],
    standalone: true,
    imports: [NgFor, MatCardModule, NgIf, MatButtonModule]
})
export class LinkListComponent implements OnInit {

  private leaseUrlCloud = environment.cubCloudUrl + 'leases';
  linkList: UserMenu[] = [];

  constructor(
    private userService: UserService,
    private loggerService: LoggerService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.getMenu();
  }

  getMenu(): void {
    this.userService.userMenu$.subscribe((menu) => {
      if (menu != null) {
        this.linkList = menu.subMenu[1].subMenu;
      }
    });
  }

  openGLDataExport() {
    this.dialog.open(GldataTransferDataComponent, {
      disableClose: true,
      width: '500px',
      position: { top: '100px'},
      data: { }
    });
  }

  callSync() {
    const url = `${this.leaseUrlCloud}/syncNewYardiObjects`;
    return this.http.post<string>(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe();
  }

  syncNewYardiObjects() {
    this.callSync().subscribe((response) => {
      if (response) {
        this.loggerService.logPageVisit('syncNewYardiObjects', 'Sync New Yardi Objects');
        alert('Sync New Yardi Objects completed successfully');
      }
    });
  }

  getLink(link: string) {
    let retLink = link;
    if (!link.startsWith('http')) {
      retLink = '/' + link;
    }
    return retLink;
  }

  utilMethod(method: string) {
    if (method === 'openGLDataExport') {
      this.openGLDataExport();
    } else if (method === 'syncNewYardiObjects') {
      this.syncNewYardiObjects();
    }
  }

  goToInternalLink(menu: UserMenu) {
    if (menu.method.length > 0)
      this.utilMethod(menu.method);
    else
    {
      this.loggerService.logPageVisit('link menu clicked', menu.name);
      this.router.navigate(['/' + menu.link], { relativeTo: this.route });
    }
  }

  goToExternalLink(menu: UserMenu) {
    this.loggerService.logPageVisit('link menu clicked', menu.name);
    window.open(menu.link);
  }
}
