

<!--- use standard link for external links and open in new tab -->
<a mat-menu-item *ngIf="menuItem.external && !menuItem.separator" [href]="getLink(menuItem.link)" target="_oldCub">
    <div class="menu-item-container">
    <fa-icon *ngIf="isIcon(menuItem.icon)" [icon]="getIcon(menuItem.icon)"></fa-icon>
    <img *ngIf="!isIcon(menuItem.icon)" src="../../../assets/img/{{menuItem.icon}}" class="icon-img" />
      {{ menuItem.name }}
    </div>
</a>
<!--- use router link for local links so the page doesn't reload -->
<a *ngIf="!menuItem.external && menuItem.method === null && !menuItem.separator" mat-menu-item [routerLink]="menuItem.link" href="#" (click)="trackClick()">
    <div class="menu-item-container">
    <fa-icon *ngIf="isIcon(menuItem.icon)" [icon]="getIcon(menuItem.icon)"></fa-icon>
    <img *ngIf="!isIcon(menuItem.icon)" src="../../../assets/img/{{menuItem.icon}}" class="icon-img" />
      {{ menuItem.name }}
    </div>
</a>
<!--- call the function passed to in the method property -->
<!-- <span *ngIf="!menuItem.external && menuItem.method !== null" mat-menu-item (click)="menuItem.method" >
  <i class="fa {{ menuItem.icon }}"></i> {{ menuItem.name }}
</span> -->
<span *ngIf="!menuItem.external && menuItem.method !== null && !menuItem.separator" mat-menu-item (click)="menuClick(menuItem)">
    <div class="menu-item-container">
    <fa-icon *ngIf="isIcon(menuItem.icon)" [icon]="getIcon(menuItem.icon)"></fa-icon>
    <img *ngIf="!isIcon(menuItem.icon)" src="../../../assets/img/{{menuItem.icon}}" class="icon-img" />
      {{ menuItem.name }}
    </div>
</span>
<a *ngIf="menuItem.separator" mat-menu-item class="separator"></a>
