import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { SearchMainComponent } from './core/search-main/search-main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './misc/page-routing/page-not-found/page-not-found.component';
import { LinkListComponent } from './user/link-list/link-list.component';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { LayoutMainComponent } from './layout-main/layout-main.component';
import { LayoutSimpleComponent } from './layout-simple/layout-simple.component';

// came with project setup
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';

/*
  You have two main routes, one for the main layout and one for the stand alone pages.
  If you want any new routes to open in a stand along fashion,
  add them to the 'nm' route. (no menu)

  If the route you are adding is available for both main and standalone pages,
  ADD IT HERE.
*/
const commonRoutes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  { path: 'links', component: LinkListComponent },
  { path: 'search', component: SearchMainComponent },
  {
    path: 'appraisals',
    loadChildren: () =>
      import('./appraisal/appraisal.module').then(m => m.AppraisalModule)
  },
  {
    path: 'closingBooks',
    loadChildren: () =>
      import('./closing-book/closing-book.module').then(
        m => m.ClosingBookModule
      )
  },
  {
    path: 'leaseBarneys',
    loadChildren: () =>
      import('./lease-barney/lease-barney.module').then(
        m => m.LeaseBarneyModule
      )
  },
  {
    path: 'tenantCompanies',
    loadChildren: () =>
      import('./tenant-company/tenant-company.module').then(
        m => m.TenantCompanyModule
      )
  },
  {
    path: 'hirrAnalysis',
    loadChildren: () =>
      import('./hirr-analysis/hirr-analysis.module').then(
        m => m.HirrAnalysisModule
      )
  },
  {
    path: 'esgPipeline',
    loadChildren: () =>
      import('./esg-pipeline/esg-pipeline.module').then(
        m => m.EsgPipelineModule
      )
  },
  {
    path: 'corpEntities',
    loadChildren: () =>
      import('./corp-entity/corp-entity.module').then(
        m => m.CorpEntityModule
      )
  },
  {
    path: 'constructionEfficiencyProjects',
    loadChildren: () =>
      import('./construction-efficiency-project/construction-efficiency-project.module').then(
        m => m.ConstructionEfficiencyProjectModule
      )
  },
  {
    path: 'gresbEfficiencyMeasures',
    loadChildren: () =>
      import('./gresb-efficiency-measures/gresb-efficiency-measures.module').then(
        m => m.GresbEfficiencyMeasuresModule
      )
  },
  {
    path: 'envApprovals',
    loadChildren: () =>
      import('./env-approval/env-approval.module').then(
        m => m.EnvApprovalModule
      )
  },
  {
    path: 'esgCertifications',
    loadChildren: () =>
      import('./esg-certifications/esg-certifications.module').then(
        m => m.EsgCertificationModule
      )
  },
  {
    path: 'esgDataMgmt',
    loadChildren: () =>
      import('./esg-data-mgmt/esg-data-mgmt.module').then(
        m => m.EsgDataMgmtModule
      )
  },
  {
    path: 'budgetLeasing',
    loadChildren: () =>
      import('./budget-leasing/budget-leasing.module').then(
        m => m.BudgetLeasingModule
      )
  },
  {
    path: 'assets',
    redirectTo: 'properties'
  },
  {
    path: 'properties',
    loadChildren: () =>
      import('./property/property.module').then(m => m.PropertyModule),
    data: { preload: true }
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'display',
    loadChildren: () =>
      import('./display/display.module').then(m => m.DisplayModule)
  },
  {
    path: 'investments',
    loadChildren: () =>
      import('./investment/investment.module').then(m => m.InvestmentModule),
    data: { preload: true }
  },
  {
    path: 'list',
    loadChildren: () =>
      import('./misc/breaking-news/breaking-news.module').then(
        m => m.BreakingNewsModule
      )
  },
  {
    path: 'breakingNews',
    loadChildren: () =>
      import('./misc/breaking-news/breaking-news.module').then(
        m => m.BreakingNewsModule
      )
  },
  {
    path: 'marchmadness',
    loadChildren: () =>
      import('./misc/march-madness/march-madness.module').then(
        m => m.MarchMadnessModule
      )
  },
  {
    path: 'marketingEvents',
    loadChildren: () =>
      import('./misc/marketing-event/marketing-event.module').then(
        m => m.MarketingEventModule
      )
  },
  {
    path: 'minutes',
    loadChildren: () =>
      import('./misc/minute/minute.module').then(m => m.MinuteModule)
  },
  {
    path: 'vendorCerts',
    loadChildren: () =>
      import('./misc/nnsa-vendor-cert/nnsa-vendor-cert.module').then(
        m => m.NnsaVendorCertModule
      )
  },
  {
    path: 'announcements',
    loadChildren: () =>
      import(
        './misc/transaction-announcement/transaction-announcement.module'
      ).then(m => m.TransactionAnnouncementModule)
  },
  {
    path: 'vehicles',
    loadChildren: () =>
      import('./misc/vehicle/vehicle.module').then(m => m.VehicleModule)
  },
  {
    path: 'vendors',
    loadChildren: () =>
      import('./misc/vendor/vendor.module').then(m => m.VendorModule)
  },
  {
    path: 'workflows',
    loadChildren: () =>
      import('./misc/workflow/workflow.module').then(m => m.WorkflowModule)
  },
  {
    path: 'gresb',
    loadChildren: () =>
      import('./misc/gresb/gresb.module').then(m => m.GresbModule)
  },
  {
    path: 'dataBucket',
    loadChildren: () =>
      import('./misc/data-bucket/data-bucket.module').then(m => m.DataBucketModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'construction',
    loadChildren: () =>
      import('./construction/construction.module').then(
        m => m.ConstructionModule
      )
  },
  {
    path: 'newconstruction',
    loadChildren: () =>
      import('./construction/construction.module').then(
        m => m.ConstructionModule
      )
  },
  {
    path: 'dispositions',
    loadChildren: () =>
      import('./disposition/disposition.module').then(m => m.DispositionModule)
  },
  {
    path: 'developments',
    loadChildren: () =>
      import('./development/development.module').then(m => m.DevelopmentModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'topTenants',
    loadChildren: () =>
      import('./tenants/tenants.module').then(m => m.TenantsModule)
  },
  {
    path: 'gldata',
    loadChildren: () =>
      import('./misc/gldata-transfer/gldata-transfer.module').then(
        m => m.GldataTransferDataModule
      )
  }
];

const routes: Routes = [
  // main layout with top and left menus
  {
    path: '',
    canActivate: [
      MsalGuard
    ],
    component: LayoutMainComponent,
    children: [
      {
        // Needed for hash routing
        path: '',
        component: DashboardComponent
      },
      ...commonRoutes,
    ]
  },
  // stand alone pages
  {
    path: 'nm',
    canActivate: [
      MsalGuard
    ],
    component: LayoutSimpleComponent,
    children: [
      ...commonRoutes,
      // came with project
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        // Needed for hash routing
        path: 'code',
        component: HomeComponent
      },
      {
        path: 'fetch-data',
        component: FetchDataComponent
      },
      {
        // Needed for Error routing
        path: 'error',
        component: HomeComponent
      },
      {
        path: 'counter',
        component: CounterComponent
      }
    ]
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // useHash: true,  // this removes the hash in the url kg: 9/17/22
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
    preloadingStrategy: SelectivePreloadingStrategyService
  })],
  exports: [RouterModule],
  providers: [SelectivePreloadingStrategyService]
})
export class AppRoutingModule { }
