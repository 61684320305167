<h1 mat-dialog-title style="margin-top: -20px">{{data.title}}</h1>
<div mat-dialog-content class="spinnerWrapper">
  <div class="spinnerRow">
    <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'" [diameter]="50" [strokeWidth]="5" *ngIf="data.mode === 'spinner'" ></mat-progress-spinner>
  </div>
  <div class="progressRow">
    <mat-progress-bar [mode]="'indeterminate'" [color]="'primary'"  *ngIf="data.mode === 'bar'"></mat-progress-bar>
  </div>
  <div class="contentRow">
      {{data.content}}
  </div>
</div>
