import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { MapService } from '../map/map.service';
import { MapInfoWindow, MapMarker, GoogleMap, GoogleMapsModule, MapAdvancedMarker } from '@angular/google-maps'
import { isThisMinute } from 'date-fns';
import { Property } from 'src/app/property';
import { environment } from '../../../environments/environment';
import { MapInfo } from './mapInfo';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { NgFor } from '@angular/common';
import { light } from './custom1';

@Component({
    selector: 'cub-map-multiple',
    templateUrl: './map-multiple.component.html',
    styleUrls: ['./map-multiple.component.scss'],
    standalone: true,
    imports: [GoogleMapsModule, NgFor]
})
export class MapMultipleComponent implements OnChanges {

  @ViewChild("googleMap", { static: false }) public googleMap: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;
  @Input() mapStyle;
  @Input() mapHeight: number;
  @Input() enabled: boolean;
  @Input() markers: MapMarker[] = [];
  @Input() advancedMarkers: MapAdvancedMarker[] = [];
  @Input() forceUpdate: boolean;
  @Output() mapViewChange: EventEmitter<any> = new EventEmitter();
  @Output() foundLatLong: EventEmitter<any> = new EventEmitter();
  @Output() addressChange: EventEmitter<any> = new EventEmitter();
  infoContent: SafeHtml = '';
  infoTitle = '';
  infoLink = '';
  infoStatusIcon = '';
  infoOcc = '';
  infoLinkText = '';
  mapInfo: MapInfo;
  private movedLat: number;
  private movedLong: number;
  private movedZoom: number;
  center: google.maps.LatLngLiteral = { lat: 41.86, lng: -88.06 };
  options: google.maps.MapOptions = {
    styles: light,
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    mapId: '6ce877400196df95'
  } as google.maps.MapOptions  // this "as google.maps.MapOptions" is required because for some reason the mapId attribute isn't exposed in the object so it creates a build error
  private bounds = new google.maps.LatLngBounds();

  mapLoaded = false;

  constructor(
    private mapService: MapService,
    private sanitized: DomSanitizer
    ) {    }

  ngOnChanges(changes: SimpleChanges) {
    let east: any = -122;
    let west: any = -70;
    let north: any = 25;
    let south: any = 47;
    this.markers.forEach(m => {
      if (m.position.lat < south) {
        south = m.position.lat;
      }
      if (m.position.lat > north) {
        north = m.position.lat;
      }
      if (m.position.lng < west) {
        west = m.position.lng;
      }
      if (m.position.lng > east) {
        east = m.position.lng;
      }
    });
    this.advancedMarkers.forEach(m => {
      if (m.position.lat < south) {
        south = m.position.lat;
      }
      if (m.position.lat > north) {
        north = m.position.lat;
      }
      if (m.position.lng < west) {
        west = m.position.lng;
      }
      if (m.position.lng > east) {
        east = m.position.lng;
      }
    });
    if (this.googleMap) {
      this.googleMap.fitBounds(new google.maps.LatLngBounds({ lat: south, lng: west }, { lat: north, lng: east }));
      this.googleMap.panTo(new google.maps.LatLngBounds({ lat: south, lng: west }, { lat: north, lng: east }).getCenter());
    }
  }

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  centerChanged() {
    let zoom = this.googleMap.getZoom();
    let lat = this.googleMap.getCenter().lat();
    let lng = this.googleMap.getCenter().lng();
    this.movedLat = lat;
    this.movedLong = lng;
    this.mapViewChange.emit({ zoomLevel: zoom, latitude: lat, longitude: lng });
  }

  zoomChanged() {
    let zoom = this.googleMap.getZoom();
    let lat = this.googleMap.getCenter().lat();
    let lng = this.googleMap.getCenter().lng();
    this.mapViewChange.emit({ zoomLevel: zoom, latitude: lat, longitude: lng });
  }

  mapMarkerClick(marker: any, content: string) {
    this.mapInfo = JSON.parse(content);
    this.infoContent = this.sanitized.bypassSecurityTrustHtml(this.mapInfo.content);
    this.info.open(marker);
  }

  mapMarkerAdvancedClick(marker: any, content: string) {

    console.log('openInfo', marker, content);
    if (marker.content.classList.contains("highlight")) {
      marker.content.classList.remove("highlight");
      marker.content.parentElement.parentElement.style.zIndex = marker.options.zIndex;
    } else {
      marker.content.classList.add("highlight");
      marker.content.parentElement.parentElement.style.zIndex = 1200;
    }

    //this.mapInfo = JSON.parse(content);
    //this.infoContent = this.sanitized.bypassSecurityTrustHtml(this.mapInfo.content);
    //this.info.open(marker);
  }

  editAddress() {
    console.log('editAddress', this.mapInfo.itemKey);
    this.addressChange.emit(this.mapInfo.itemKey)
  }
}
